<template>
  <form
    class="form-horizontal"
    @submit.prevent="submitAuth()"
    @keydown="form.errors.clear($event.target.name)"
  >
    <se-field label-text="Email" class="mb-3">
      <se-input
        id="user-email"
        v-model="form.username"
        name="email"
        size="medium"
        placeholder="Enter your email address"
        :disabled="false"
        required
        type="email"
        :invalid="form.errors.has('email')"
        autofocus
      >
        <template #prefix>
          <SeIcon name="mail" :size="20" />
        </template>
      </se-input>
      <se-field-message v-if="form.errors.has('username')" type="error">
        {{ form.errors.get('username').join(', ') }}
      </se-field-message>
    </se-field>
    <se-field label-text="Password" class="mb-3">
      <se-input-password
        id="user-password"
        v-model="form.password"
        name="password"
        placeholder="Enter your password"
        size="medium"
        :invalid="form.errors.has('password')"
      >
        <template #prefix>
          <SeIcon name="lock" :size="20" />
        </template>
      </se-input-password>
      <se-field-message v-if="form.errors.has('password')" type="error">
        {{ form.errors.get('password').join(', ') }}
      </se-field-message>
      <se-field-message v-if="error?.message" type="error">
        {{ error.message }}
      </se-field-message>
    </se-field>

    <div class="form-group row">
      <div class="col-md-6 col-12 text-center text-sm-left" />
      <div class="col-md-6 col-12 float-sm-left text-end text-sm-right">
        <Link
          class="text-black xs:hover:text-primary-100 no-underline xs:hover:underline"
          href="/reset-password"
        >
          Forgot password?
        </Link>
      </div>
    </div>

    <se-button
      type="primary"
      size="large"
      native-type="submit"
      :loading="loading"
      :disabled="!form.username || !form.password"
      :icon-only="false"
      class="w-full"
    >
      Sign in
    </se-button>
  </form>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { getGlobalProperties } from '@/src/shared/helpers/getGlobalProperties';
import Link from '@/src/components/Link';
import SeIcon from '@/src/shared/ui-kit/icon/Icon';
import Form from '@/src/helpers/form-validation';
import SeInput from '@/src/shared/ui-kit/input/Input';
import SeButton from '@/src/shared/ui-kit/button/Button';
import SeField from '@/src/shared/ui-kit/field/Field';
import SeFieldMessage from '@/src/shared/ui-kit/field-message/FieldMessage';
import SeInputPassword from '@/src/shared/ui-kit/input-password/InputPassword';

const {
  $auth,
} = getGlobalProperties();

const { redirect } = defineProps({
  redirect: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['success']);

const form = ref(new Form({
  grant_type: 'password',
  username: '',
  password: '',
}));
const error = ref(null);
const loading = ref(false);

const submitAuth = () => {
  loading.value = true;
  error.value = null;
  $auth.login({
    rememberMe: true,
    redirect,
    data: {
      grant_type: 'password',
      username: form.value.username,
      password: form.value.password,
    },
    success: async () => {
      loading.value = false;
      emit('success');
    },
    error: (err) => {
      loading.value = false;
      if (typeof err.response === 'object') {
        form.value.errors = new Map(Object.entries(err.response));
      }
      if (err?.response?.message) {
        error.value = { message: err.response.message };
      } else {
        error.value = { message: 'The credentials you supplied were not correct.' };
      }
    },
  });
};
</script>

<script lang="ts">
export default {
  name: 'SeAuthLoginForm',
};
</script>
